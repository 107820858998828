<template>
  <div>
    <a-form-model
      class="mb-4"
      ref="profileForm"
      :model="form"
      :rules="rules"
      @submit="handleSubmit"
    >
      <a-row>
        <a-col :span="12" class="p-2">
          <a-form-model-item ref="name" prop="name" label="Account name">
            <a-input v-model="form.name"
                     placeholder="Account name"
            >
              <a-icon slot="prefix" type="user" class="text-primary"/>
            </a-input>
          </a-form-model-item>
        </a-col>
        <a-col :span="12" class="p-2">
          <a-form-model-item ref="email" prop="email" label="Email">
            <a-input v-model="form.email"
                     placeholder="Email"
            >
              <a-icon slot="prefix" type="mail" class="text-primary"/>
            </a-input>
          </a-form-model-item>
        </a-col>
      </a-row>
      <a-row>
        <a-col :span="12" class="p-2">
          <a-form-model-item ref="new_password" prop="new_password" label="New password">
            <a-input-password v-model="form.new_password"
                              placeholder="Type your new password"
                              type="password"
            >
              <a-icon slot="prefix" type="unlock" class="text-primary"/>
            </a-input-password>
          </a-form-model-item>
        </a-col>
        <a-col :span="12" class="p-2">
          <a-form-model-item ref="confirm" prop="confirm" label="Repeat new password">
            <a-input-password v-model="form.confirm"
                              placeholder="Repeat New Password"
                              type="password"
            >
              <a-icon slot="prefix" type="unlock" class="text-primary"/>
            </a-input-password>
          </a-form-model-item>
        </a-col>
      </a-row>
      <a-row>
        <a-col>
          <a-form-model-item ref="password" prop="password" label="Your current password">
            <a-input-password v-model="form.password"
                              placeholder="Type your password to confirm changes"
                              type="password"
            >
              <a-icon slot="prefix" type="unlock" class="text-primary"/>
            </a-input-password>
          </a-form-model-item>
        </a-col>
      </a-row>
      <a-row>
        <a-col>
          <a-form-model-item label="Your timezone">
            <a-select
              v-model="currentlyTimezone.value"
              @change="handleChangeChangeTimezone"
              :loading="currentlyTimezoneLoading"
              :disabled="currentlyTimezoneLoading"
            >
              <a-select-option
                v-for="timezone in availableTimezones"
                :value="timezone.value"
                :key="timezone.value"
              >
                {{ timezone.value }} {{ timezone.utsOffset }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
        </a-col>
      </a-row>
      <a-row>
        <a-col>
          <a-form-model-item>
            <a-upload-dragger
              name="file"
              :multiple="false"
              action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
              @change="handleUpload"
            >
              <p class="ant-upload-drag-icon">
                <a-icon type="inbox"/>
              </p>
              <p class="ant-upload-text">
                New avatar picture
              </p>
              <p class="ant-upload-hint">
                Click or drag file to this area to upload
              </p>
            </a-upload-dragger>
          </a-form-model-item>
        </a-col>
      </a-row>
      <div class="text-right">
        <a-button type="primary" @click="handleSubmit" :disabled="!isChanged">
          Update
        </a-button>
        <a-button style="margin-left: 1rem;" @click="closeProfileModal">
          Cancel
        </a-button>
      </div>
    </a-form-model>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import apiClient from '@/services/axios'
import { message } from 'ant-design-vue'
import Vue from 'vue'

export default {
  name: 'profile.vue',
  props: ['userInfo'],
  computed: {
    ...mapState(['user']),
    loading() {
      return this.processing
    },
    isChanged() {
      if (this.form.name === this.user.name && this.form.email === this.user.email && this.form.new_password.length === 0) {
        return false
      } else {
        return true
      }
    },
  },
  data() {
    const validateNewPass = (rule, value, callback) => {
      if (value !== '') {
        if (this.form.confirm !== '') {
          this.$refs.profileForm.validateField('confirm')
        }
        callback()
      } else {
        callback()
      }
    }
    const validateConfirm = (rule, value, callback) => {
      if (value !== this.form.new_password) {
        callback(new Error('Two passwords are different!'))
      } else {
        callback()
      }
    }
    const currentlyTimezone = this.$store.getters['user/getUserTimezoneSetting']
    const currentlyTimezoneLoading = false
    const availableTimezones = [
      {
        value: 'Etc/UTC',
        utsOffset: '+00:00',
      },
      {
        value: 'Europe/Moscow',
        utsOffset: '+03:00',
      },
      {
        value: 'Asia/Almaty',
        utsOffset: '+06:00',
      },
    ]
    return {
      currentlyTimezoneLoading,
      currentlyTimezone,
      availableTimezones,
      processing: false,
      form: {
        name: this.userInfo.name,
        email: this.userInfo.email,
        password: '',
        new_password: '',
        confirm: '',
      },
      rules: {
        name: [{ required: true, message: 'Please input account name', trigger: 'change' }],
        email: [{ required: true, message: 'Please input email', trigger: 'change' }],
        password: [
          { required: true, message: 'Please input your current password to confirm changes', trigger: 'change' },
        ],
        new_password: [
          { validator: validateNewPass, trigger: 'change' },
        ],
        confirm: [
          { validator: validateConfirm, trigger: 'change' },
        ],
      },
    }
  },
  mounted() {
    // ---
  },
  methods: {
    async handleChangeChangeTimezone() {
      try {
        this.currentlyTimezoneLoading = true
        await this.$store.dispatch('user/UPDATE_USER_SETTING', this.currentlyTimezone)
        Vue.prototype.$notification.success({
          message: 'Success',
        })
      } catch (e) {
        Vue.prototype.$notification.error({
          message: 'Error',
          description: e.message,
        })
      } finally {
        this.currentlyTimezoneLoading = false
      }
    },
    handleUpload(info) {
      const status = info.file.status
      if (status !== 'uploading') {
        console.log(info.file, info.fileList)
      }
      if (status === 'done') {
        message.success(`${info.file.name} file uploaded successfully.`)
      } else if (status === 'error') {
        message.error(`${info.file.name} file upload failed.`)
      }
    },
    handleSubmit(e) {
      e.preventDefault()
      this.$refs.profileForm.validate(valid => {
        if (valid) {
          this.processing = true
          this.updateProfile()
        } else {
          console.log('not valid')
          return false
        }
      })
    },
    closeProfileModal() {
      this.$emit('closeModalFunction')
    },
    updateProfile() {
      const url = '/user/update'
      const sendData = {}
      sendData.password = this.form.password
      if (this.form.name !== this.user.name) {
        sendData.name = this.form.name
      }
      if (this.form.email !== this.user.email) {
        sendData.email = this.form.email
      }
      if (this.form.new_password.length > 0) {
        sendData.new_password = this.form.new_password
      }
      console.log(sendData)
      return apiClient.patch(url, sendData).then((response) => {
        console.log(response)
        this.$notification.success({
          message: 'Your profile information successfully updated',
          description: '',
        })
        this.processing = false
        this.$emit('submitModalFunction', response.data.data)
      }).catch(error => {
        console.log(error)
        this.$notification.error({
          message: 'Error while updating profile information',
          description: error.message,
        })
        this.processing = false
      })
    },
  },
}
</script>

<style scoped>

</style>
