import localeAntd from 'ant-design-vue/lib/locale-provider/en_US'

const messages = {
  topBar: {
    issuesHistory: 'Issues History',
    projectManagement: 'Project Management',
    typeToSearch: 'Search...',
    findPages: 'Find pages...',
    actions: 'Actions',
    status: 'Status',
    profileMenu: {
      hello: 'Hello',
      billingPlan: 'Billing Plan',
      role: 'Role',
      email: 'Email',
      phone: 'Phone',
      editProfile: 'Edit Profile',
      logout: 'Logout',
    },
    baseRate: {
      title: 'Base rate',
      rub: 'RUB',
      others: 'Others',
    },
    panelManagement: {
      title: 'Control panel',
      main: 'Main',
      users: 'Users',
      others: 'Others',
      roles: 'Roles & Permissions',
      promocodes: 'Promocodes',
      analytics: 'Analytics',
      binancio: 'Binancio',
      settings: 'Settings',
      psystems: 'Payment methods',
      currencies: 'Currencies & Rates',
      projects: 'Projects',
      chatBlacklist: 'Restricted words',
    },
  },
  buttons: {
    btnCancel: 'Cancel',
    btnDefault: 'Default',
    btnAdd: 'Add',
    btnNew: 'New',
    btnCreate: 'Create',
    btnEdit: 'Edit',
    btnRefresh: 'Refresh',
    btnDelete: 'Delete',
    btnOpen: 'Open',
    btnClose: 'Close',
    btnBan: 'Ban',
    btnUnban: 'Unban',
    btnApply: 'Apply',
    btnAllow: 'Allow',
    btnSave: 'Save',
    btnActivate: 'Activate',
    btnClick: 'Click',
    btnStop: 'Stop',
    btnChange: 'Change',
    btnSettings: 'Settings',
    btnBind: 'Bind',
    btnSignIn: 'Sign In',
    btnSignUp: 'Sign Up',
    btnRegister: 'Register',
    btnLogIn: 'Log In',
    btnRememberMe: 'Remember me',
    btnExport: 'Export',
    btnDownload: 'Download',
    btnWriteOff: 'Write Off',
    btnAccrue: 'Accrue',
    btnAccept: 'Accept',
    btnBuy: 'Buy',
    btnSell: 'Sell',
    btnDeposit: 'Deposit',
    btnWithdraw: 'Withdraw',
    btnType: 'Type',
  },
  pre: {
    in: ' in ',
    on: ' on ',
    from: ' from ',
    at: ' at ',
    to: ' to ',
    of: ' of ',
    or: ' or ',
    for: ' for ',
    all: 'All',
    any: 'Any',
    no: 'No',
    not: 'Not',
    with: 'with',
    due: ' due to ',
    eg: 'e.g. ',
    etc: ' etc.',
    me: 'me',
    my: 'my',
    by: 'by ',
  },
  by: {
    admin: 'Administrator',
    block: 'Blocking',
    ban: 'Ban',
  },
  chatBlacklist: {
    addBtn: 'Add',
    removeConfirm: 'Are you sure?',
    addWordModal: {
      addTitle: 'Add word to blacklist',
      updateTitle: 'Update the blacklisted word',
      newWord: 'New word',
    },
  },
  common: {
    chatBlacklist: 'Chat words blacklist',
    base: 'Base',
    custom: 'Custom',
    import: 'Import',
    buy: 'Buy',
    payouts: 'Payouts',
    sell: 'Sell',
    usdt: 'USDT',
    count: 'count',
    admin: 'admin',
    part: 'part',
    sex: 'Sex',
    file: 'File',
    name: 'Name',
    sum: 'Sum',
    operation: 'Operation',
    balance: 'Balance',
    history: 'History',
    archive: 'Archive',
    authorization: 'Authorization',
    remember: 'Remember',
    contact: 'Contacts',
    example: 'Example',
    card: 'Card',
    total: 'Total',
    time: 'Time',
    phone: 'Phone',
    month: 'Month',
    thisMonth: 'This Month',
    loading: 'Loading',
    online: 'Online',
    offline: 'Offline',
    status: 'Status',
    tariff: 'Tariff',
    billing: 'Billing',
    plan: 'Plan',
    available: 'Available',
    limit: 'Limit',
    linked: 'linked to ',
    stopped: 'Stopped',
    stoppedBy: 'Stopped by ',
    margin: 'Margin',
    sure: 'Sure',
    temporary: 'Temporary',
    analytics: 'Analytics',
    chat: 'Chat',
    chats: 'Chats',
    message: 'Message',
    messages: 'Messages',
    address: 'Address',
    addresses: 'Addresses',
    agent: 'Agent',
    agents: 'Agents',
    project: 'Project',
    projects: 'Projects',
    currency: 'Currency',
    currencies: 'Currencies',
    client: 'Client',
    clients: 'Clients',
    order: 'Order',
    orders: 'Orders',
    deposit: 'Deposit',
    deposits: 'Deposits',
    rate: 'Rate',
    rates: 'Rates',
    payment: 'Payment',
    payments: 'Payments',
    purchase: 'Purchase',
    purchases: 'Purchases',
    sale: 'Sale',
    sales: 'Sales',
    requisite: 'Requisite',
    requisites: 'Requisites',
    amount: 'Amount',
    sender: 'Sender',
    comment: 'Comment',
    comments: 'Comments',
    report: 'Report',
    reports: 'Reports',
    promocode: 'Promo-code',
    promocodes: 'Promo-codes',
    paySystem: 'Pay system',
    paySystems: 'Pay systems',
    banned: 'Banned',
    unbanned: 'Unbanned',
    invoice: 'Invoice',
    invoices: 'Invoices',
    invoiceMaster: 'Master-Invoice',
    cryptoInvoice: 'Crypto-invoice',
    cryptoInvoices: 'Crypto-invoices',
    projectDeposits: 'Project deposits',
    billed: 'Billed',
    paid: 'Paid',
    unpaid: 'Unpaid',
    txId: 'TxID',
    group: 'group',
    ungroup: 'ungroup',
    arbitration: 'arbitration',
    common: 'Common',
    global: 'Global',
    personal: 'Personal',
    mobile: 'Mobile',
    interface: 'Interface',
    coward: 'Coward',
    reward: 'Reward',
    rewards: 'Rewards',
    inRotation: 'in rotation',
    flow: 'Flow',
    flows: 'Flows',
    record: 'Record',
    records: 'Records',
    transaction: 'Transaction',
    transactions: 'Transactions',
    income: 'Income',
    outcome: 'Outcome',
    types: 'Types',
    model: 'Model',
    models: 'Models',
    debt: 'Debt',
  },
  messages: {
    copiedToClipboard: 'Copied to clipboard',
    errorDeletingInvoice: 'Error while deleting invoice',
    errorAcceptInvoice: 'Error while accepting invoice',
    errorDeletingAgent: 'Error while deleting agent',
    errorCreatingAgent: 'Error while creating a new agent',
    errorCreatingRequisite: 'Error while creating a new requisite',
    errorUpdatingAgent: 'Error while updating agent settings',
    errorChangingAgentStatus: 'Failed to change account status',
    errorChangingStatus: 'Failed to change status',
    newAgentCreated: 'New agent created',
    agentSettingsUpdated: 'Agent settings updated',
    requisiteStatusUpdated: 'Requisites status changed',
    errorRequisiteSetDefault: 'Failed to set requisites as default',
    errorDeletingRequisite: 'Error while deleting requisites',
    requisitesDeleted: 'Requisites deleted',
    requisiteCreated: 'Requisite is created',
    requisiteUpdated: 'Requisite is updated',
  },
  placeholders: {
    enterClientSearch: 'Enter client search text',
    enterClientLogin: 'Enter client login',
    enterClientExtId: 'Enter client ExtID',
    selectClientLogin: 'Select client login (user_name)',
    selectPaymentId: 'Select payment ID or ExtID',
    selectSearch: 'Search...',
    enterPaymentUuid: 'Enter payment UUID',
    enterPaymentExtId: 'Enter payment ExtID',
    selectAgent: 'Select agent',
    selectType: 'Select type',
    selectModel: 'Select model',
    selectStatus: 'Select status',
    selectCurrency: 'Select currency',
    selectProject: 'Select project',
    selectProjects: 'Select projects',
    selectPlan: 'Select plan',
    selectTariff: 'Select tariff',
    selectInvoiceStatus: 'Select invoice status',
    selectAddressType: 'Select address type',
    selectPaySystem: 'Select Payment Method',
    requisiteAddress: 'eg. card, account, phone',
    requisiteAgentComment: 'The client will see this information with the payment details',
    selectStartDate: 'Select start date',
    selectEndDate: 'Select end date',
    commentForOperation: 'Comment for this operation',
  },
  validations: {
    agentNameRequired: 'Enter an account name',
    senderNameRequired: 'Enter an sender name',
    currencyRequired: 'Specify currency',
    requisiteNameRequired: 'Enter requisites name',
    requisiteAddressRequired: 'Enter requisites address',
    psystemRequired: 'Select pay method',
    amountRequired: 'Enter an amount',
    addressTypeRequired: 'Select address type',
    psystemIsRequired: 'Psystem is required',
  },
  labels: {
    minPurchaseAmount: 'Min. purchase amount',
    minAmount: 'Min. amount',
    minSum: 'Min. sum',
    minSaleAmount: 'Min. sale amount',
    maxDebtAmount: 'Max. debt amount',
    fixedPart: 'Fixed part',
    percentPaymentAmount: '% of payment amount',
    incMaxDebtByBalance: 'Increase max. debt by balance amount',
    addressType: 'Address type',
    paymentMethod: 'Payment method',
    paymentLimit: 'Payment limit',
    openInNewWindow: 'Open in new window',
  },
  tooltips: {
    copyToClipboard: 'Copy to Clipboard',
    show: 'Show',
    succeededOrders: 'Succeeded orders',
    succeededPayments: 'Succeeded payments',
    allOrders: 'All orders',
    allPayments: 'All payments',
    allPurchases: 'All purchases',
    allSales: 'All sales',
    acceptPayment: 'Accept payment',
    acceptInvoice: 'Accept invoice',
    invoiceWriteOffDebt: 'Write off debt (Delete) ?',
    invoiceWaitingAddress: 'Awaiting payment address',
    invoiceGetAddressError: 'Payment address not received, error',
    invoiceWillDeleteOnIdle: 'Will be deleted when idle',
    makeRequisitesDefaultForAnyBank: 'Set this requisites as default when choosing \'Any bank\'',
    projectPurchaseNotified: 'Processed by project api',
    projectPurchaseNotNotified: 'Awaiting processing by project api',
  },
  popups: {
    acceptInvoice: 'Funds may not have been received. Accept payment anyway?',
    sureToActivatePromocode: 'Sure to activate promocode for payment?',
    sureToConfirmPayment: 'Sure to confirm payment?',
    sureToCancelPayment: 'Sure to cancel payment?',
  },
  statuses: {
    payment: {
      pending: 'awaiting payment',
      paid: 'paid',
    },
    accepted: 'accepted',
    canceled: 'canceled',
    confirmed: 'confirmed',
    activated: 'activated',
    paid: 'paid',
    created: 'created',
    expired: 'expired',
    deleted: 'deleted',
    stopped: 'stopped',
    changed: 'changed',
    fixed: 'fixed',
  },
}

export default {
  locale: 'en-US',
  localeAntd,
  messages,
}
