import Vue from 'vue'
import Router from 'vue-router'
import AuthLayout from '@/layouts/Auth'
import MainLayout from '@/layouts/Main'
import ProjectsLayout from '@/layouts/Main/Projects'
import AgentsLayout from '@/layouts/Main/Agents'
import store from '@/store'

Vue.use(Router)

const router = new Router({
  base: process.env.BASE_URL,
  // mode: 'history',
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      redirect: '/purchases', // 'dashboard/alpha',
      component: MainLayout,
      meta: {
        authRequired: true,
        hidden: true,
      },
      breadcrumbName: 'Главная',
      children: [
        {
          path: '/dashboard',
          meta: {
            title: 'Dashboard',
          },
          component: () => import('./views/dashboard/olipay'),
        },
        {
          path: '/users',
          name: 'users',
          meta: {
            title: 'Users',
            crumbName: 'Users',
          },
          component: () => import('./views/apps/users'),
        },
        {
          path: '/agents',
          name: 'agents',
          meta: {
            title: 'Accounts',
            crumbName: 'Accounts',
          },
          component: AgentsLayout,
          children: [
            {
              path: 'list',
              name: 'agents.list',
              meta: {
                title: 'Agents List',
                crumbName: 'Agents List',
              },
              component: () => import('./views/accounts'),
              // props: true,
            },
            {
              path: 'payouts',
              name: 'agent.payouts',
              meta: {
                title: 'Payouts',
                crumbName: 'Payouts',
              },
              component: () => import('./views/payouts'),
              // props: true,
            },
            {
              path: 'invoices',
              name: 'agent.invoices',
              meta: {
                title: 'Invoices',
                crumbName: 'Invoices',
              },
              component: () => import('./views/invoices'),
              // props: true,
            },
            {
              path: 'balance_flows',
              name: 'agent.balance_operations',
              meta: {
                title: 'Balance flows',
                crumbName: 'Balance flows',
              },
              component: () => import('./views/accounts/balance/operations.vue'),
              // props: true,
            },
            {
              path: 'rejections',
              name: 'agent.rejections',
              meta: {
                title: 'Order rejections',
                crumbName: 'rejections',
              },
              component: () => import('./views/accounts/rejections/index.vue'),
              // props: true,
            },
          ],
        },
        {
          path: '/agents/payments/:accountId',
          name: 'account-payments',
          meta: {
            title: 'Payments',
            crumbName: 'Payments',
          },
          component: () => import('./views/bills'),
          props: true,
        },
        {
          path: '/transactions/:accountId?',
          name: 'transactions',
          meta: {
            title: 'Transactions',
            crumbName: 'Transactions',
          },
          component: () => import('./views/transactions'),
          props: true,
        },
        {
          path: '/purchases',
          name: 'purchases',
          meta: {
            title: 'Purchases',
            crumbName: 'Purchases',
          },
          component: () => import('./views/bills'),
          // props: true,
        },
        {
          path: '/sales',
          name: 'sales',
          meta: {
            title: 'Sales',
            crumbName: 'Sales',
          },
          component: () => import('./views/sales'),
          // props: true,
        },
        {
          path: '/transfers',
          name: 'client_transfers',
          meta: {
            title: 'Transfers',
            crumbName: 'Transfers',
          },
          component: () => import('./views/transfers'),
          // props: true,
        },
        {
          path: '/projects',
          name: 'project',
          meta: {
            title: 'Projects',
            crumbName: 'Projects',
          },
          component: ProjectsLayout,
          children: [
            {
              path: 'list',
              name: 'projects.list',
              meta: {
                title: 'Projects List',
                crumbName: 'Projects List',
              },
              component: () => import('./views/projects/index.vue'),
              // props: true,
            },
            {
              path: 'deposits',
              name: 'project.deposits',
              meta: {
                title: 'Deposits',
                crumbName: 'Deposits',
              },
              component: () => import('./views/projects/deposits/index.vue'),
              // props: true,
            },
          ],
        },
        {
          path: '/promocodes',
          name: 'promocodes',
          meta: {
            title: 'Promo-codes',
            crumbName: 'Promo-codes',
          },
          component: () => import('./views/promocodes'),
        },
        {
          path: '/promocodes/list/:groupId',
          name: 'promocode-list',
          meta: {
            title: 'Promo-codes',
            crumbName: 'Promo-codes',
          },
          component: () => import('./views/promocodes/list'),
          props: true,
        },
        {
          path: '/psystems',
          name: 'psystems',
          meta: {
            title: 'Pay systems',
            crumbName: 'Pay systems',
          },
          component: () => import('./views/psystems'),
        },
        {
          path: '/currencies',
          name: 'currencies',
          meta: {
            title: 'Currencies',
            crumbName: 'Currencies',
          },
          component: () => import('./views/currencies'),
        },
        {
          path: '/clients',
          name: 'clients',
          meta: {
            title: 'Clients',
            crumbName: 'Clients',
          },
          component: () => import('./views/clients'),
          // props: true,
        },
        {
          path: '/analytics',
          meta: {
            title: 'Analytics',
            crumbName: 'Analytics',
          },
          component: () => import('./views/analytics'),
        },
        {
          path: '/binancio',
          meta: {
            title: 'Binancio',
            crumbName: 'Binancio',
          },
          component: () => import('./views/binancio'),
        },
        {
          path: '/chat-blacklist',
          meta: {
            title: 'Word blacklist of chat',
            crumbName: 'Word blacklist of chat',
          },
          component: () => import('./views/chat-blacklist'),
        },
        {
          path: '/permissions',
          meta: {
            title: 'Roles & permissions',
          },
          component: () => import('./views/permissions'),
        },
      ],
    },

    // System Pages
    {
      path: '/auth',
      component: AuthLayout,
      redirect: 'auth/login',
      children: [
        // {
        //   path: '/auth/404',
        //   meta: {
        //     title: 'Error 404',
        //   },
        //   component: () => import('./views/auth/404'),
        // },
        // {
        //   path: '/auth/500',
        //   meta: {
        //     title: 'Error 500',
        //   },
        //   component: () => import('./views/auth/500'),
        // },
        {
          path: '/auth/login',
          meta: {
            title: 'Sign In',
          },
          component: () => import('./views/auth/login'),
        },
        {
          path: '/auth/register',
          meta: {
            title: 'Sign Up',
          },
          component: () => import('./views/auth/register'),
        },
        {
          path: '/auth/forgot-password',
          meta: {
            title: 'Forgot Password',
          },
          component: () => import('./views/auth/forgot-password'),
        },
        // {
        //   path: '/auth/lockscreen',
        //   meta: {
        //     title: 'Lockscreen',
        //   },
        //   component: () => import('./views/auth/lockscreen'),
        // },
      ],
    },

    // Redirect to 404
    {
      path: '*', redirect: 'auth/404', hidden: true,
    },
  ],
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.authRequired)) {
    if (!store.state.user.authorized) {
      next({
        path: '/auth/login',
        query: { redirect: to.fullPath },
      })
    } else {
      next()
    }
  } else {
    next()
  }
})

export default router
