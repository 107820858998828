import apiClient from '../../axios'

class ClientService {
  getClients(reqData) {
    return apiClient
      .get('/admin/clients', { params: reqData })
      .then((response) => {
        return response
      })
  }

  banClient(clientId, banData) {
    return apiClient
      .patch(`/admin/clients/${clientId}/ban`, banData)
      .then((response) => {
        return response.data.data
      })
  }

  unBanClient(clientId) {
    return apiClient
      .patch(`/admin/clients/${clientId}/unban`)
      .then((response) => {
        return response.data.data
      })
  }

  getClientBalance(clientId) {
    return apiClient
      .get(`/admin/clients/${clientId}/balance`)
      .then((response) => {
        return response.data.data
      })
  }

  getClientBalanceOperations(clientId, reqData) {
    return apiClient
      .get(`/admin/clients/${clientId}/balance/operations`, { params: reqData })
      .then((response) => {
        return response
      })
  }

  createClientBalanceOperation(clientId, reqData) {
    const data = { type: reqData.type, amount: reqData.amount, comment: reqData.comment }
    return apiClient
      .post(`/admin/clients/${clientId}/balance/operations`, data)
      .then((response) => {
        return response.data.data
      })
  }

  /**
   * Unbind client phone number
  */
  unbindPhoneNumber(clientId) {
    return apiClient
      .patch(`/admin/clients/${clientId}/unbind_phone`)
      .then((response) => {
        return response.data.data
      })
  }
}
export default new ClientService()
