export default {
  splitLastOccurrence(str, substring, shouldReturn = 'all') {
    const lastIndex = str.lastIndexOf(substring)
    const before = str.slice(0, lastIndex)
    const after = str.slice(lastIndex + 1)
    switch (shouldReturn) {
      case 'before': return before
      case 'after': return after
      case 'all': return [before, after]
      default: return [before, after]
    }
  },
}
