import apiClient from '../axios'
// import chatHeader from './chat-header'
import clientService from './client/client.service'
import transferService from './transfers/transfer.service'

class ApiService {
  client = clientService
  transfer = transferService
  // PAY-SYSTEMS BEGIN ---------------
  getPsystems(active = false, currencyId = false, targetType = 'p2p') {
    const act = active ? 'active' : ''
    const cur = currencyId ? `currency_id=${currencyId}` : ''
    let query = act.length > 0 ? act + '&' : ''
    query += cur + `target=${targetType}`
    return apiClient
      .get(`/admin/ps?${query}`)
      .then((response) => {
        return response.data.data
      })
  }

  updatePsystemSort(sortData) {
    return apiClient
      .patch('/admin/ps/update_sort', sortData)
      .then((response) => {
        return response.data.data
      })
  }

  getProjects(activeOnly = false, short = false) {
    const query = `active=${activeOnly}&short=${short}`
    return apiClient
      .get(`/admin/projects?${query}`)
      .then((response) => {
        return response.data.data
      })
  }

  // PAY-SYSTEMS END ---------------

  // CLIENT PAYMENTS BEGIN ---------------

  getClientPayments(data) {
    return apiClient
      .get('/admin/payments', { params: data })
      .then((response) => {
        return response
      })
  }

  confirmClientPayment(paymentId) {
    return apiClient
      .patch(`/admin/payments/${paymentId}/confirm`)
      .then((response) => {
        return response
      })
  }

  getClientPaymentStatusHistory(paymentId) {
    return apiClient
      .get(`/admin/payments/${paymentId}/history`)
      .then((response) => {
        return response.data.data
      })
  }

  getClientPaymentWebhooks(paymentId) {
    return apiClient
      .get(`/admin/payments/${paymentId}/webhooks`)
      .then((response) => {
        return response.data.data
      })
  }

  getClientPaymentRecipients(paymentId) {
    return apiClient
      .get(`/admin/payments/${paymentId}/recipients`)
      .then((response) => {
        return response.data.data
      })
  }

  sendPaymentWebhook(paymentId, status = null) {
    const data = { payment_id: paymentId, status: status }
    return apiClient
      .post('/admin/payments/send_webhook', data)
      .then((response) => {
        return response
      })
  }

  // CLIENT PAYMENTS END ---------------

  // CLIENT SALES BEGIN ---------------

  getClientSales(data) {
    return apiClient
      .get('/admin/sales?accepted', { params: data })
      .then((response) => {
        return response
      })
  }

  confirmClientSale(paymentId) {
    return apiClient
      .patch(`/admin/sales/${paymentId}/confirm`)
      .then((response) => {
        return response
      })
  }

  cancelClientSale(paymentId) {
    return apiClient
      .patch(`/admin/sales/${paymentId}/cancel`)
      .then((response) => {
        return response
      })
  }
  // CLIENT SALES END ---------------

  // PROMOCODES BEGIN ---------------

  activatePromocode(promoId) {
    return apiClient
      .post(`/admin/promocodes/${promoId}/activate`)
      .then((response) => {
        return response
      })
  }

  // PROMOCODES END ---------------

  // AGENT REQUISITES BEGIN ---------------

  getRequisiteAddressTypes() {
    return apiClient
      .get('/admin/address-types')
      .then((response) => {
        return response.data.data
      })
  }

  getAccountRequisites(accountId, params = []) {
    return apiClient
      .get(`/admin/accounts/${accountId}/requisites`, { params: params })
      .then((response) => {
        return response.data
      })
  }

  /**
   * Fetch all requisites of agent
   * @param accountId
   * @param params
   * @returns {Promise<AxiosResponse<any>>}
   */
  getAccountRequisitesB(accountId, params = []) {
    return apiClient
      .get(`/admin/accounts/${accountId}/requisites_b`, { params: params })
      .then((response) => {
        return response.data
      })
  }

  changeRequisiteActive(requisiteId, active) {
    return apiClient
      .get(`/admin/accounts/requisites/${requisiteId}/active/${active}`)
      .then((response) => {
        return response.data.data
      })
  }

  changeRequisiteActiveB(requisiteId, active) {
    return apiClient
      .get(`/admin/accounts/requisites/b/${requisiteId}/active/${active}`)
      .then((response) => {
        return response.data.data
      })
  }

  fetchAllPsystemsForRequisitesB(requisiteId, active) {
    return apiClient
      .get('/admin/accounts/requisites/b/psystems')
      .then((response) => {
        return response.data.data
      })
  }

  createRequisiteB(params) {
    return apiClient
      .post('/admin/accounts/requisites/b', params)
      .then((response) => {
        return response.data.data
      })
  }

  updateRequisiteB(params) {
    return apiClient
      .patch('/admin/accounts/requisites/b/' + params.id, params)
      .then((response) => {
        return response.data.data
      })
  }

  setRequisiteDefault(requisiteId) {
    return apiClient
      .get(`/admin/accounts/requisites/${requisiteId}/default`)
      .then((response) => {
        return response.data.data
      })
  }

  createRequisite(data) {
    return apiClient
      .post('/admin/accounts/requisites', data)
      .then((response) => {
        return response.data.data
      })
  }

  updateRequisite(requisiteId, data) {
    return apiClient
      .patch(`/admin/accounts/requisites/${requisiteId}`, data)
      .then((response) => {
        return response.data.data
      })
  }

  deleteRequisite(requisiteId) {
    return apiClient
      .delete(`/admin/accounts/requisites/${requisiteId}`)
      .then((response) => {
        return response
      })
  }

  deleteRequisiteB(requisiteId) {
    return apiClient
      .delete(`/admin/accounts/requisites/b/${requisiteId}`)
      .then((response) => {
        return response
      })
  }

  // AGENT REQUISITES END ---------------

  // BEGIN AGENT BALANCE OPERATIONS (BALANCE FLOWS) ---------------

  /**
   * Fetch all requisites of agent
   * @param params
   * @returns {Promise<AxiosResponse<any>>}
   */
  getAccountBalanceOperations(params) {
    return apiClient
      .get('/admin/accounts/balance/operations', { params: params })
      .then((response) => {
        return response
      })
  }

  getAccountBalanceOperationsDigits(params) {
    return apiClient
      .get('/admin/accounts/balance/operations/analytics/digits', { params: params })
      .then((response) => {
        return response
      })
  }

  getAccountBalanceFlowDigits(params) {
    return apiClient
      .get('/admin/accounts/balance/operations/analytics/flows', { params: params })
      .then((response) => {
        return response.data.data
      })
  }

  getAccountsDebtAndBalanceDigits(params) {
    return apiClient
      .get('/admin/accounts/analytics/balance_debt', { params: params })
      .then((response) => {
        return response.data.data
      })
  }

  // END AGENT BALANCE OPERATIONS (BALANCE FLOWS) ---------------

  getSettings() {
    return apiClient
      .get('/admin/settings')
      .then((response) => {
        return response.data.data
      })
  }

  setSetting(settingName, value) {
    return apiClient
      .get(`/admin/settings/set/${settingName}/${value}`)
      .then((response) => {
        return response
      })
  }

  getAccounts(reqData) {
    return apiClient
      .get('/admin/accounts', { params: reqData })
      .then((response) => {
        return response
      })
  }

  getAccountStats(accountId, month) {
    return apiClient
      .get(`/admin/accounts/${accountId}/stats?month=${month}`)
      .then((response) => {
        return response
      })
  }

  // --- BEGIN REWARD TYPES AND REWARDS --------------------
  getAccountRewards(accountId, month, reqData) {
    return apiClient
      .get(`/admin/accounts/${accountId}/rewards?month=${month}`, { params: reqData })
      .then((response) => {
        return response
      })
  }

  getAccountRewardTypes(active) {
    const act = active ? '?active' : ''
    return apiClient
      .get(`/admin/accounts/reward_plan${act}`)
      .then((response) => {
        return response.data.data
      })
  }

  // --- END REWARD TYPES AND REWARDS --------------------

  getAccountAccruals(accountId, month, reqData) {
    return apiClient
      .get(`/admin/accounts/${accountId}/accruals?month=${month}`, { params: reqData })
      .then((response) => {
        return response
      })
  }

  deleteAccountAccrual(accrualId) {
    return apiClient
      .delete(`/admin/accounts/accruals/${accrualId}`)
      .then((response) => {
        return response
      })
  }

  // --- BEGIN AGENT REJECTIONS --------------------

  getAgentRejections(reqData) {
    return apiClient
      .get('/admin/accounts/rejections', { params: reqData })
      .then((response) => {
        return response
      })
  }

  getAgentRejectionStats(reqData) {
    return apiClient
      .get('/admin/accounts/rejections/analytics/digits', { params: reqData })
      .then((response) => {
        return response
      })
  }

  // --- END AGENT REJECTIONS --------------------

  // --- BEGIN AGENT INTERCONNECTS --------------------

  getAgentInterconnects(agentId) {
    return apiClient
      .get(`/admin/accounts/${agentId}/interconnects`)
      .then((response) => {
        return response.data.data
      })
  }

  createAgentInterconnect(agentId, interconnectData) {
    return apiClient
      .post(`/admin/accounts/${agentId}/interconnects/`, interconnectData)
      .then((response) => {
        return response.data.data
      })
  }

  updateAgentInterconnect(agentId, interconnectId, interconnectData) {
    return apiClient
      .patch(`/admin/accounts/${agentId}/interconnects/${interconnectId}`, interconnectData)
      .then((response) => {
        return response.data.data
      })
  }

  deleteAgentInterconnect(agentId, interconnectId) {
    return apiClient
      .delete(`/admin/accounts/${agentId}/interconnects/${interconnectId}`)
      .then((response) => {
        return response.data.data
      })
  }

  // --- END AGENT INTERCONNECTS --------------------

  agentIncreaseBuyLimit(agentId, amount) {
    return apiClient
      .patch(`/admin/accounts/${agentId}/buy_limit/buy`, { amount: amount })
      .then((response) => {
        return response
      })
  }

  agentDecreaseBuyLimit(agentId, amount) {
    return apiClient
      .patch(`/admin/accounts/${agentId}/buy_limit/sell`, { amount: amount })
      .then((response) => {
        return response
      })
  }

  agentDecreaseBuyLimitAndWriteOff(agentId, amount) {
    return apiClient
      .patch(`/admin/accounts/${agentId}/buy_limit/sell_write_off`, { amount: amount })
      .then((response) => {
        return response
      })
  }

  agentEditBuyLimit(editType, agentId, amount) {
    switch (editType) {
      case 'buy': return this.agentIncreaseBuyLimit(agentId, amount)
      case 'sell': return this.agentDecreaseBuyLimit(agentId, amount)
      case 'sell_and_write_off': return this.agentDecreaseBuyLimitAndWriteOff(agentId, amount)
      default: return false
    }
  }

  // CURRENCIES, RATES:  BEGIN ---------------

  getCurrencies(active = false) {
    let query = '?no_crypto'
    query = active ? query + '&active' : query
    return apiClient
      .get(`/admin/currencies${query}`)
      .then((response) => {
        return response.data.data
      })
  }

  createCurrency(currencyData) {
    return apiClient
      .post('/admin/currencies', currencyData)
      .then((response) => {
        return response
      })
  }

  updateCurrency(currencyId, currencyData) {
    return apiClient
      .patch(`/admin/currencies/${currencyId}`, currencyData)
      .then((response) => {
        return response
      })
  }

  deleteCurrency(currencyId) {
    return apiClient
      .delete(`/admin/currencies/${currencyId}`)
      .then((response) => {
        return response
      })
  }

  updateCurrencyRate(currencyRateId, rateData) {
    return apiClient
      .patch(`/admin/currencies/rates/${currencyRateId}`, rateData)
      .then((response) => {
        return response
      })
  }

  updateRateCharges(currencyRateId, rateChargesData) {
    return apiClient
      .patch(`/admin/currencies/rates/${currencyRateId}/charges`, rateChargesData)
      .then((response) => {
        return response
      })
  }

  refreshBaseRate(currencyRateId) {
    return apiClient
      .patch(`/admin/currencies/rates/${currencyRateId}/base`)
      .then((response) => {
        return response
      })
  }

  getBinanceRate(currency, sellAmount) {
    return apiClient
      .post(`/admin/binancio/${currency}`, { amount: sellAmount })
      .then((response) => {
        return response
      })
  }

  getCurrencyRateDigits(params) {
    return apiClient
      .get('/admin/currencies/rates/analytics/digits', { params: params })
      .then((response) => {
        return response.data.data
      })
  }

  // CURRENCIES, RATES:  END ---------------

  // PROJECT SECTION:  BEGIN ---------------
  getProjectsDeposits(reqData) {
    return apiClient
      .get('/admin/projects/deposits', { params: reqData })
      .then((response) => {
        return response
      })
  }

  projectToggleWithdrawalEnabled(projectId) {
    return apiClient
      .patch(`/admin/projects/${projectId}/settings/toggle_withdrawals`)
      .then((response) => {
        return response.data.data
      })
  }

  // PROJECT SECTION:  END ---------------

  // AGENT ROTATION SECTION:  BEGIN ---------------
  updateAgentBuyRotationState(agentId, state) {
    return apiClient
      .patch(`/admin/accounts/${agentId}/rotation/buy/${state}`)
      .then((response) => {
        return response
      })
  }

  // AGENT ROTATION SECTION:  END ---------------
}
export default new ApiService()
