<template>
  <div :class="$style.topbar">
    <div :class="$style.logoContainer">
      <router-link to="/" style="margin:0; padding:0">
<!--      <div :class="$style.logo">-->
<!--&lt;!&ndash;          <img src="resources/images/logo.png" class="mr-2" alt="Olipay.cc logo" width="32" />&ndash;&gt;-->
<!--          <div :class="$style.name" style="width: 120px"><span class="text-dark">{{ settings.logo }}</span></div>-->
<!--      </div>-->
      </router-link>
    </div>
<!--    <div class="mr-auto">-->
<!--      <cui-search />-->
<!--    </div>-->
    <div class="mr-auto ml-5">
<!--      <span>-->
<!--        <router-link to="/dashboard"><a-icon type="dashboard" class="mt-1 font-size-18" /> Сводка</router-link>-->
<!--      </span>-->
<!--      <span class="ml-lg-5 ml-sm-2 ml-md-3" v-if="(userCanView(user, 'accounts'))">-->
<!--        <router-link to="/accounts"><font-awesome-icon icon="user-tie" class="mt-1 font-size-14" /> Агенты</router-link>-->
<!--      </span>-->
<!--      <span class="ml-lg-5 ml-sm-2 ml-md-3" v-if="(userCanView(user, 'clients')) && 0 === 0">-->
<!--        <router-link to="/clients"><font-awesome-icon icon="user-friends" class="mt-1 font-size-16" /> Клиенты</router-link>-->
<!--      </span>-->
<!--      <span class="ml-lg-5 ml-sm-2 ml-md-3" v-if="(userCanView(user, 'transactions'))">-->
<!--        <router-link to="/payments"><font-awesome-icon icon="money-check" class="mt-1 font-size-14" /> Заявки</router-link>-->
<!--      </span>-->
<!--      <span class="ml-lg-5 ml-sm-2 ml-md-3" v-if="(userCanView(user, 'crypto'))">-->
<!--        <router-link to="/invoices"><font-awesome-icon icon="money-check-alt" class="mt-1 font-size-14" /> Crypto Счета</router-link>-->
<!--      </span>-->
<!--      <span class="ml-lg-5 ml-sm-2 ml-md-3" v-if="(userCanView(user, 'analytics'))">-->
<!--        <router-link to="/analytics"><font-awesome-icon icon="chart-area" class="mt-1 font-size-14" /> Аналитика</router-link>-->
<!--      </span>-->
<!--      <span class="ml-5">-->
<!--        <router-link to="/payments"><a-icon type="dollar" class="mt-1 font-size-18" /> Выплаты</router-link>-->
<!--      </span>-->
    </div>
<!--    <div class="mr-4 d-none d-md-block">-->
<!--      <cui-issues-history />-->
<!--    </div>-->
<!--    <div class="mr-5">-->
<!--      <router-link to="/dashboard"><a-icon type="dashboard" class="mt-1 font-size-18" /> Dashboard</router-link>-->
<!--    </div>-->
<!--    <div class="mr-5">-->
<!--      <router-link to="/projects"><a-icon type="project" class="mt-1 font-size-18" /> Projects</router-link>-->
<!--    </div>-->
<!--    <div class="mb-0 mr-auto d-xl-block d-none">-->
<!--      <cui-project-management />-->
<!--    </div>-->
<!--    <div class="mr-4">-->
<!--&lt;!&ndash;      <tp-cloud-store />&ndash;&gt;-->
<!--&lt;!&ndash;      <tp-settings />&ndash;&gt;-->
<!--    </div>-->
<!--    <div class="mr-4">-->
<!--      <cui-fav-pages />-->
<!--    </div>-->
<!--    <div class="mr-4 d-none d-sm-block">-->
<!--      <cui-language-switcher />-->
<!--    </div>-->
<!--    <div class="mr-4 d-none d-sm-block">-->
<!--      <cui-actions />-->
<!--    </div>-->
    <div class="pl-2" v-if="userHasRole('admin') || userHasRole('subadmin') || userCanView(user, 'currencies')">
      <cui-base-rate-rub />
    </div>
    <div class="pl-2" v-if="userHasRole('admin') || userHasRole('subadmin')">
      <cui-panel-management />
    </div>
    <div class="pl-4">
      <cui-user-menu />
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
// import CuiIssuesHistory from './IssuesHistory'
// import CuiProjectManagement from './ProjectManagement'
import CuiPanelManagement from './PanelManagement'
import CuiBaseRateRub from './BaseRateRub'
// import CuiSearch from './Search'
// import CuiLanguageSwitcher from './LanguageSwitcher'
// import CuiActions from './Actions'
import CuiUserMenu from './UserMenu'
// import CuiFavPages from './FavPages'
// import TpCloudStore from './CloudStore'
// import TpSettings from './Settings'
import accessMix from '@/services/accessmix.js'

export default {
  mixins: [accessMix],
  components: {
    CuiBaseRateRub,
    // CuiIssuesHistory,
    // CuiProjectManagement,
    // CuiSearch,
    // CuiLanguageSwitcher,
    // CuiActions,
    CuiPanelManagement,
    CuiUserMenu,
    // CuiFavPages,
    // TpCloudStore,
    // TpSettings,
  },
  computed: {
    ...mapState(['settings']),
    ...mapGetters('user', ['user']),
  },
}
</script>

<style lang="scss" module>
@import "./style.module.scss";
</style>
