import apiClient from '../../axios'

class TransferService {
  getTransfers(data) {
    return apiClient
      .get('/admin/transfers', { params: data })
      .then((response) => {
        return response
      })
  }
}
export default new TransferService()
