export const getMenuData = [
  {
    category: true,
    title: 'Main menu',
  },
  {
    title: 'Buy',
    key: 'payments',
    url: '/purchases',
    icon: 'fe fe-credit-card',
    roles: ['admin'],
  },
  {
    title: 'Sell',
    key: 'sales',
    url: '/sales',
    icon: 'fe fe-credit-card',
    roles: ['admin'],
  },
  {
    title: 'Transfers',
    key: 'client_transfers',
    url: '/transfers',
    icon: 'fe fe-credit-card',
    roles: ['admin'],
  },
  {
    title: 'Clients',
    key: 'clients',
    url: '/clients',
    icon: 'fe fe-users',
    roles: ['admin'],
  },
  {
    title: 'Agents',
    key: 'account',
    // url: '/agents',
    // roles: ['admin'],
    children: [
      {
        title: 'Agent List',
        key: 'accounts',
        url: '/agents/list',
        icon: 'fe fe-users',
      },
      {
        title: 'Payouts',
        key: 'account_payouts',
        url: '/agents/payouts',
        icon: 'fe fe-credit-card',
        roles: ['admin'],
      },
      {
        title: 'Invoices',
        key: 'crypto',
        url: '/agents/invoices',
        icon: 'fe fe-dollar-sign',
        roles: ['admin'],
      },
      {
        title: 'Balance flows',
        key: 'account.balance_operations',
        url: '/agents/balance_flows',
        icon: 'fe fe-trending-up',
        roles: ['admin'],
      },
      {
        title: 'Order rejections',
        key: 'rejections',
        url: '/agents/rejections',
        icon: 'fe fe-dollar-sign',
        roles: ['admin'],
      },
    ],
  },
  {
    title: 'Users',
    key: 'users',
    url: '/users',
    icon: 'fe fe-users', // fe fe-layers
    hidden: true,
    roles: ['admin'],
  },
  {
    title: 'Transactions',
    key: 'transactions',
    url: '/transactions',
    icon: 'team',
    hidden: true,
    roles: ['admin'],
  },
  {
    title: 'Projects',
    key: 'project',
    // url: '#',
    children: [
      {
        title: 'Projects List',
        key: 'projects',
        url: '/projects/list',
        icon: 'fe fe-layers',
      },
      {
        title: 'API Keys',
        key: 'project.api',
        url: '#',
        icon: 'fe fe-link-2',
      },
      {
        title: 'Deposits',
        key: 'project.deposits',
        url: '/projects/deposits',
        icon: 'fe fe-dollar-sign',
      },
      {
        title: 'Statistics',
        key: 'project.statistics',
        url: '#',
        icon: 'fe fe-pie-chart',
      },
    ],
  },
  {
    title: 'Promocodes',
    key: 'promocodes-list',
    url: '/promocodes/list/1',
    icon: 'tags',
    roles: ['admin'],
    hidden: true,
  },
  {
    title: 'Settings',
    key: 'settings',
    url: '/settings',
    icon: 'team',
    roles: ['admin'],
    hidden: true,
  },
  {
    title: 'Pay-systems and Currencies',
    key: 'psystems',
    url: '/psystems',
    icon: 'team',
    roles: ['admin'],
    hidden: true,
  },
]
