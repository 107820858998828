import Vue from 'vue'
import Vuex from 'vuex'
import user from './user'
import settings from './settings'
import ps from './ps'
import ApiService from '../services/api/api.service'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    user,
    settings,
    ps,
  },
  state: {
    currencies: [],
    psystems: [],
    projectList: [],
    accountRewardPlans: [],
    requisiteAddressTypes: [],
  },
  mutations: {
    setCurrencies (state, currencies) {
      state.currencies = currencies
    },
    setPsystems (state, psystems) {
      state.psystems = psystems
    },
    setProjectList (state, projects) {
      state.projectList = projects
    },
    setAccountRewardPlans (state, plans) {
      state.accountRewardPlans = plans
    },
    setRequisiteAddressTypes(state, addressTypes) {
      state.requisiteAddressTypes = addressTypes
    },
    addPsystem (state, psystem) {
      state.psystems.unshift(psystem)
    },
    updatePsystem(state, psystem) {
      const ind = state.psystems.findIndex(obj => obj.id === psystem.id)
      state.psystems[ind] = psystem
    },
    deletePsystem(state, psystemId) {
      const ind = state.psystems.findIndex(obj => obj.id === psystemId)
      state.psystems.splice(ind, 1)
    },
  },
  actions: {
    //
    getCurrencies({ commit, dispatch }) {
      return ApiService.getCurrencies().then(
        response => {
          commit('setCurrencies', response)
          return response
        },
        error => {
          return Promise.reject(error)
        },
      )
    },
    getPsystems({ commit, dispatch }) {
      return ApiService.getPsystems().then(
        response => {
          commit('setPsystems', response)
          return response
        },
        error => {
          return Promise.reject(error)
        },
      )
    },
    getRequisiteAddressTypes({ commit, dispatch }) {
      return ApiService.getRequisiteAddressTypes().then(
        response => {
          commit('setRequisiteAddressTypes', response)
          return response
        },
        error => {
          return Promise.reject(error)
        },
      )
    },
    getProjectList({ commit, dispatch }) {
      return ApiService.getProjects(false, true).then(
        response => {
          commit('setProjectList', response)
          return response
        },
        error => {
          return Promise.reject(error)
        },
      )
    },
    getAccountRewardPlans({ commit, dispatch }) {
      return ApiService.getAccountRewardTypes().then(
        response => {
          commit('setAccountRewardPlans', response)
          return response
        },
        error => {
          return Promise.reject(error)
        },
      )
    },
  },
  getters: {
    currencies: state => state.currencies.sort((a, b) => b.is_active - a.is_active),
    activeCurrencies: state => state.currencies.filter(obj => obj.is_active === true),
    psystems: state => state.psystems,
    activePsystems: state => state.psystems.filter(obj => obj.is_active === true),
    projectList: state => state.projectList.sort((a, b) => b.is_active - a.is_active),
    activeProjectList: state => state.projectList.filter(obj => obj.is_active === true),
    accountRewardPlans: state => state.accountRewardPlans,
    requisiteAddressTypes: state => state.requisiteAddressTypes,
  },
})
