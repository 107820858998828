import Vue from 'vue'

import helpers from '@/helpers/helper'

export default {
  install: () => {
    Vue.prototype.helpers = helpers
    Vue.helpers = helpers
  },
}
